<template>
    <div>
        <!--form add & update faculty-->
        <ek-dialog
            :btnText="facultyDto.type == 1 ? 'اضافة صف' : 'اضافة الكلية'"
            ref="dialog"
            :isEdit="!!facultyDto.id"
            :title="
                facultyDto.id
                    ? facultyDto.type == 1
                        ? 'تعديل صف'
                        : 'تعديل الكلية'
                    : facultyDto.type == 1
                    ? 'اضافة صف'
                    : 'اضافة الكلية'
            "
            @delete="deleteFac(facultyDto.id)"
            :placeholder="
                facultyDto.type == 1 ? 'ابحث عن صف محدد ' : 'ابحث عن كلية محدد'
            "
            @ok="submitForm"
            @close="resetForm"
            @search="setSearch"
        >
            <template #body>
                <validationObserver ref="facultyForm">
                    <ek-input-text
                        v-model="facultyDto.name"
                        name="اسم "
                        placeholder="ادخل اسم الصف"
                        :label="
                            facultyDto.type === 1 ? 'اسم الصف ' : 'اسم الكلية '
                        "
                        :rules="[
                            {
                                type: 'required',
                                message: `${
                                    facultyDto.type === 1
                                        ? 'اسم الصف مطلوب'
                                        : 'اسم الكلية مطلوب'
                                }`,
                            },
                        ]"
                    ></ek-input-text>

                    <ek-input-select
                        name="type"
                        placeholder="تابع للمرحلة"
                        label="المرحلة التابعة لها  "
                        :options="[
                {
                    name: 'مرحلة التعليم المدرسي',
                    id: 1,
                },
                {
                    name: 'مرحلة التعليم الجامعي',
                    id: 0,
                },
            ],"
                        v-model="facultyDto.type"
                    ></ek-input-select>
                    <ek-input-select
                        name="nameUniversity"
                        :placeholder="
                            facultyDto.type == 0
                                ? 'حدد الجامعة التابعة لها'
                                : 'حدد hglvpgm التابعة لها'
                        "
                        label="الجامعة التابعة لها  "
                        :options="universityName"
                        v-model="facultyDto.universityId"
                    ></ek-input-select>

                    <template
                        v-if="
                            facultyDto.id &&
                            facultyDto.departments.length &&
                            facultyDto.type == 0
                        "
                    >
                        <label>الفروع</label>

                        <b-list-group v-if="facultyDto.departments.length">
                            <b-list-group-item
                                v-for="item in facultyDto.departments"
                                :key="item"
                            >
                                {{
                                    universityName
                                        .find(
                                            (u) =>
                                                u.id == facultyDto.universityId
                                        )
                                        .faculties.find(
                                            (f) => f.id === facultyDto.id
                                        )
                                        .departments.find((d) => d.id == item)
                                        .name
                                }}
                            </b-list-group-item>
                        </b-list-group>
                    </template>

                    <b-form-checkbox
                        v-if="!facultyDto.id && facultyDto.type == 0"
                        v-model="select"
                        >فرع عام</b-form-checkbox
                    >
                    <ek-input-text
                        v-if="
                            select == 1 &&
                            !facultyDto.id &&
                            facultyDto.type == 0
                        "
                        v-model="facultyDto.yearCount"
                        name="count"
                        placeholder="ادخل  عدد سنوات الفرع"
                        label="عدد سنوات الفرع العام"
                    ></ek-input-text>
                </validationObserver>
            </template>
        </ek-dialog>
    </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
    computed: {
        ...mapState({
            facultyDto: (state) => state.faculty.facultyDto,
            isDialogOpen: (state) => state.faculty.isDialogOpen,
            universityName: (state) => state.university.universityName,
        }),
    },
    data() {
        return {
            select: 0,
        };
    },
    methods: {
        ...mapActions(["addFaculty", "updateFaculty"]),
        //submit add & update faculty
        submitForm() {
            this.$refs.facultyForm.validate().then((suc) => {
                if (suc) {
                    if (this.facultyDto.id) {
                        this.updateFaculty(this.facultyDto);
                        this.$refs.dialog.close();
                    } else {
                        this.addFaculty({
                            ...this.facultyDto,
                            type: this.facultyDto.type,
                        })
                            .then(() => {
                                this.$refs.dialog.close();
                            })
                            .catch(() => {});
                    }
                }
            });
        },
        deleteFac(id) {
            this.$store.dispatch("deleteFaculties", [id]);
        },
        resetForm() {
            this.$refs.facultyForm.reset();
            this.$store.commit("SET_FACULTY_DTO");
            this.$store.commit("IS_DIALOG_OPEN", false);
        },
        setSearch(query) {
            this.$store.commit("Set_Search_Dto", {
                keys: ["name", "facultiesCount", "studentsCount"],
                query,
            });
        },
    },
    watch: {
        isDialogOpen(nv) {
            if (nv) {
                this.$refs.dialog.open();
            }
        },
    },
};
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ek-dialog',{ref:"dialog",attrs:{"btnText":_vm.facultyDto.type == 1 ? 'اضافة صف' : 'اضافة الكلية',"isEdit":!!_vm.facultyDto.id,"title":_vm.facultyDto.id
                ? _vm.facultyDto.type == 1
                    ? 'تعديل صف'
                    : 'تعديل الكلية'
                : _vm.facultyDto.type == 1
                ? 'اضافة صف'
                : 'اضافة الكلية',"placeholder":_vm.facultyDto.type == 1 ? 'ابحث عن صف محدد ' : 'ابحث عن كلية محدد'},on:{"delete":function($event){return _vm.deleteFac(_vm.facultyDto.id)},"ok":_vm.submitForm,"close":_vm.resetForm,"search":_vm.setSearch},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('validationObserver',{ref:"facultyForm"},[_c('ek-input-text',{attrs:{"name":"اسم ","placeholder":"ادخل اسم الصف","label":_vm.facultyDto.type === 1 ? 'اسم الصف ' : 'اسم الكلية ',"rules":[
                        {
                            type: 'required',
                            message: ("" + (_vm.facultyDto.type === 1
                                    ? 'اسم الصف مطلوب'
                                    : 'اسم الكلية مطلوب')),
                        } ]},model:{value:(_vm.facultyDto.name),callback:function ($$v) {_vm.$set(_vm.facultyDto, "name", $$v)},expression:"facultyDto.name"}}),_c('ek-input-select',{attrs:{"name":"type","placeholder":"تابع للمرحلة","label":"المرحلة التابعة لها  ","options":[
            {
                name: 'مرحلة التعليم المدرسي',
                id: 1,
            },
            {
                name: 'مرحلة التعليم الجامعي',
                id: 0,
            } ],},model:{value:(_vm.facultyDto.type),callback:function ($$v) {_vm.$set(_vm.facultyDto, "type", $$v)},expression:"facultyDto.type"}}),_c('ek-input-select',{attrs:{"name":"nameUniversity","placeholder":_vm.facultyDto.type == 0
                            ? 'حدد الجامعة التابعة لها'
                            : 'حدد hglvpgm التابعة لها',"label":"الجامعة التابعة لها  ","options":_vm.universityName},model:{value:(_vm.facultyDto.universityId),callback:function ($$v) {_vm.$set(_vm.facultyDto, "universityId", $$v)},expression:"facultyDto.universityId"}}),(
                        _vm.facultyDto.id &&
                        _vm.facultyDto.departments.length &&
                        _vm.facultyDto.type == 0
                    )?[_c('label',[_vm._v("الفروع")]),(_vm.facultyDto.departments.length)?_c('b-list-group',_vm._l((_vm.facultyDto.departments),function(item){return _c('b-list-group-item',{key:item},[_vm._v(" "+_vm._s(_vm.universityName .find( function (u) { return u.id == _vm.facultyDto.universityId; } ) .faculties.find( function (f) { return f.id === _vm.facultyDto.id; } ) .departments.find(function (d) { return d.id == item; }) .name)+" ")])}),1):_vm._e()]:_vm._e(),(!_vm.facultyDto.id && _vm.facultyDto.type == 0)?_c('b-form-checkbox',{model:{value:(_vm.select),callback:function ($$v) {_vm.select=$$v},expression:"select"}},[_vm._v("فرع عام")]):_vm._e(),(
                        _vm.select == 1 &&
                        !_vm.facultyDto.id &&
                        _vm.facultyDto.type == 0
                    )?_c('ek-input-text',{attrs:{"name":"count","placeholder":"ادخل  عدد سنوات الفرع","label":"عدد سنوات الفرع العام"},model:{value:(_vm.facultyDto.yearCount),callback:function ($$v) {_vm.$set(_vm.facultyDto, "yearCount", $$v)},expression:"facultyDto.yearCount"}}):_vm._e()],2)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }